import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Fade, makeStyles } from '@material-ui/core';
import CustomButton from '../customButton';
import ActionButton from '../actionButton';
import { ReactComponent as NotificationIcon } from '../../../assets/icons/bell.svg';
import { isArrayEmpty, isObjectEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    backgroundColor: primary.bluish8,
    boxSizing: 'border-box',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: spacing(14.5, 14, 14),
    width: '100%',
  },
  icon: {
    marginBottom: spacing(3),
  },
  title: {
    textAlign: 'center',
  },
  content: {
    textAlign: 'center',
  },
  contentMargin: {
    marginTop: spacing(2),
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(4),
  },
  actionButton: {
    marginRight: spacing(4),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  iconButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(4),
    width: '100%',
  },
}));

const NotificationCard = ({
  className,
  title,
  titleVariant,
  content,
  hasIcon,
  actionButtons,
  iconButtonProps,
  shouldFade,
  iconClass,
  customIcon: CustomIcon,
}) => {
  const classes = useStyles();

  const getIcon = () =>
    CustomIcon ? (
      <CustomIcon className={classNames(classes.icon, iconClass)} />
    ) : (
      <NotificationIcon className={classNames(classes.icon, iconClass)} />
    );

  return (
    <Fade in={shouldFade} unmountOnExit>
      <div className={classNames(classes.main, className)}>
        {hasIcon && getIcon()}
        {title && (
          <Typography variant={titleVariant} className={classes.title}>
            {title}
          </Typography>
        )}
        {content && (
          <Typography
            variant="body2"
            className={classNames(classes.content, {
              [classes.contentMargin]: title,
            })}
          >
            {content}
          </Typography>
        )}
        {!isArrayEmpty(actionButtons) && (
          <div className={classes.actionButtons}>
            {actionButtons.map(
              (
                {
                  className: customButtonClass,
                  type,
                  onButtonClick,
                  label,
                  isDisabled,
                  ...rest
                },
                index
              ) => (
                <CustomButton
                  key={`action_button_${index}`}
                  className={classNames(
                    classes.actionButton,
                    customButtonClass
                  )}
                  type={type}
                  disabled={isDisabled}
                  onClick={onButtonClick}
                  {...rest}
                >
                  {label}
                </CustomButton>
              )
            )}
          </div>
        )}
        {!isObjectEmpty(iconButtonProps) && (
          <div className={classes.iconButtonWrapper}>
            <ActionButton {...iconButtonProps} />
          </div>
        )}
      </div>
    </Fade>
  );
};

NotificationCard.defaultProps = {
  className: undefined,
  iconClass: undefined,
  content: '',
  title: '',
  titleVariant: 'h5',
  customIcon: null,
  actionButtons: [],
  iconButtonProps: {},
  hasIcon: false,
  shouldFade: true,
};

NotificationCard.propTypes = {
  className: PropTypes.string,
  iconClass: PropTypes.string,
  title: PropTypes.string,
  titleVariant: PropTypes.string,
  content: PropTypes.string,
  customIcon: PropTypes.object,
  actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
  shouldFade: PropTypes.bool,
  hasIcon: PropTypes.bool,
  iconButtonProps: PropTypes.object,
};

export default NotificationCard;
